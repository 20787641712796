@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply m-0 font-sans text-text-muted bg-background antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    @apply font-mono;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold text-text mb-4;
  }

  h1 {
    @apply text-h1;
  }

  h2 {
    @apply text-h2;
  }

  h3 {
    @apply text-h3;
  }
}

@layer components {
  .button {
    @apply bg-secondary hover:bg-tertiary text-white border-none py-3 px-5 m-2 rounded cursor-pointer;
  }

  .section-heading {
    @apply text-xl text-text border-b border-secondary pb-2 mb-4;
  }

  .item-summary {
    @apply flex justify-between bg-secondary p-2 rounded;
  }

  .text-details {
    @apply bg-secondary p-4 mt-2 rounded text-small leading-tight;
  }

  .side-panel p {
    @apply mb-1;
  }

  .side-panel ul {
    @apply list-none pl-0 mb-1;
  }

  .side-panel li {
    @apply mb-1;
  }

  .side-panel h2 {
    @apply pb-0 mb-0;
  }

  .side-panel h3 {
    @apply border-b border-tertiary pb-2 mb-2;
  }

  .modal h3 {
    @apply border-b-0;
  }

  .underlined-container {
    border-bottom: 1px solid #303030;
    padding-bottom: 8px;
  }
}

@layer utilities {
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
}
